import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '@/views/LoginComponent.vue';
import EmployeeDashboard from '@/views/EmployeeDashboard.vue';
import UserProfile from '@/views/UserProfile.vue';
import EmployeeProfile from '@/views/EmployeeProfile.vue';
import EmployerDashboard from '@/views/EmployerDashboard.vue';
import CreateOrg from '@/views/CreateOrg.vue';
import CreateJobListing from '@/views/CreateJobListing.vue';
import CreateJobSeeking from '@/views/CreateJobSeeking.vue';
import UserTypeSelection from '@/views/UserTypeSelection.vue';
import HealthCheck from '@/views/HealthCheck.vue';

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: LoginComponent },
  { path: '/user-type', component: UserTypeSelection },
  { path: '/employer/:userId', name: 'EmployerDashboard', component: EmployerDashboard, props: true },
  { path: '/employee/:userId', name: 'EmployeeDashboard', component: EmployeeDashboard, props: true },
  { path: '/employee/:userId/userProfile', name: 'UserProfile', component: UserProfile, props: true },
  { path: '/employee/:userId/employeeProfile', name: 'EmployeeProfile', component: EmployeeProfile, props: true },
  { path: '/employer/:userId/createOrg', name: 'CreateOrg', component: CreateOrg, props: true },
  { path: '/employer/:userId/createJob', name: 'CreateJobListing', component: CreateJobListing, props: true },
  { path: '/employer/:userId/createSeeking', name: 'CreateJobSeeking', component: CreateJobSeeking, props: true },
  { path: '/health', component: HealthCheck }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
