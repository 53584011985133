<template>
  <div>
    <h1>Job Applications</h1>
    <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Date of Application</th>
          <th>Status</th>
          <th v-if="userStore.profile.userType == 2">Employee Name</th>
          <th>Job Listing</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="jobApplication in jobApplications" :key="jobApplication.id">
          <td>{{ formatDate(jobApplication.dateOfApplication) }}</td>
          <td>{{ jobApplication.status }}</td>
          <td v-if="userStore.profile.userType == 2">{{ jobApplication.employeeName }}</td>
          <td>{{ jobApplication.jobListingTitle }}</td>
          <td v-if="userStore.profile.userType == 1"><button @click="remove(jobApplication)">Delete</button></td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>
  
<script setup>
import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { ref, onMounted, inject } from 'vue'

const props = defineProps(['userId'])
const jobApplications = ref([])

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})


const userStore = useUserStore()
const signalR = inject('$signalR');

signalR.on('jobApplicationCreated', (application) => {
  console.log('Job application created:', application)
  jobApplications.value.push(application)
});
signalR.on('jobApplicationDeleted', (application) => {
  console.log('Job application deleted:', application)
  jobApplications.value = jobApplications.value.filter(item => item.id !== application.id)
})


onMounted(() => {
  console.log('User id:', props.userId);
  if (userStore.profile.userType == 2) {
    api.get('/api/job-application/employer/' + props.userId)
      .then(response => {
        jobApplications.value = response.data
      })
  }
  else if (userStore.profile.userType == 1) {
    api.get('/api/job-application/employee/' + props.userId)
      .then(response => {
        jobApplications.value = response.data
      })
  }
});

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString().replace(',', '');
  }

  const remove = (jobApplication) => {
    console.log('Removing job application:', jobApplication);
    api.delete('/api/job-application/' + jobApplication.id)
      .then(() => {
        console.log('Job application removed:', jobApplication);
      })
  };

</script>

<style scoped>

.table-container {
  display: flex;
  justify-content: center;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
}

th, td {
  padding: 3px;
  text-align: left;
  border: 1px solid #ddd;
}

</style>