<template>
  <div class="create-job">
    <h1>Employee Profile</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="skills">Skills:</label>
        <select id="skills" v-model="selectedSkills" multiple>
          <option v-for="skill in skills" :key="skill.id" :value="skill.id">{{ skill.name }}</option>
        </select>
      </div>
      <div class="container">
      <div class="experiences">
        <h2>Experiences</h2>
        <Experiences :userId="userId"/>
        <div v-if="!addingExperience">
          <button type="button" @click="addingExperience = true">Add Experience</button>
        </div>
        <div v-if="addingExperience">
          <div class="form-group">
            <label for="role">Role:</label>
            <select :id="'role-' + index" v-model="newExperience.role" required>
              <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="employmentType">Employment Type:</label>
            <select id="employmentType" v-model="newExperience.employmentType" required>
              <option v-for="employmentType in employmentTypes" :key="employmentType.id" :value="employmentType.id">{{ employmentType.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="startDate">Start Date:</label>
            <input type="date" id="startDate" v-model="newExperience.startDate" required>
          </div>
          <div class="form-group">
            <label for="endDate">End Date:</label>
            <input type="date" id="endDate" v-model="newExperience.endDate" required>
          </div>
          <div class="form-group">
            <label for="location">Location:</label>
            <GMapAutocomplete placeholder="" @place_changed="setPlace">
          </GMapAutocomplete>
          </div>
          <div class="form-group">
            <label for="company">Company:</label>
            <input type="text" id="company" v-model="newExperience.company" required>
          </div>
          <button type="button" @click="addExperience">Create Experience</button>
          <button type="button" @click="addingExperience = false">Cancel</button>
        </div>
      </div>
      <div class="qualifications">
        <h2>Qualifications</h2>
        <Qualifications :userId="userId"/>
        <div v-if="!addingQualification">
          <button type="button" @click="addingQualification = true">Add Qualification</button>
        </div>
        <div v-if="addingQualification">
          <div class="form-group">
            <label for="name">School:</label>
            <input type="text" id="name" v-model="newQualification.school" required>
          </div>
          <div class="form-group">
            <label for="field">Field of Study:</label>
            <select id="field" v-model="newQualification.field" required>
              <option v-for="field in fields" :key="field.id" :value="field.id">{{ field.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="degreeLevel">Degree Level:</label>
            <select id="degreeLevel" v-model="newQualification.degreeLevel" required>
              <option v-for="degreeLevel in degreeLevels" :key="degreeLevel.id" :value="degreeLevel.id">{{ degreeLevel.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="startDate">Start Date:</label>
            <input type="date" id="startDate" v-model="newQualification.startDate" required>
          </div>
          <div class="form-group">
            <label for="endDate">End Date:</label>
            <input type="date" id="endDate" v-model="newQualification.endDate" required>
          </div>
          <button type="button" @click="addQualification">Create Qualification</button>
          <button type="button" @click="addingQualification = false">Cancel</button>
        </div>
      </div>
      </div>
      <button type="submit">Save</button>
    </form>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { inject } from 'vue';
import { useUserStore } from '@/stores/user';
import Experiences from '@/components/Experiences.vue';
import Qualifications from '@/components/Qualifications.vue';
import router from '@/router';

const props = defineProps({
  userId: {
    type: Number,
    required: true
  }
});

const userId = props.userId;

const api = inject('$api');
const userStore = useUserStore();

const skills = ref([]);
const roles = ref([]);
const employmentTypes = ref([])
const fields = ref([]);
const degreeLevels = ref([]);

const selectedSkills = ref([]);

const addingExperience = ref(false);
const addingQualification = ref(false);

const newExperience = reactive({
  role: null,
  employmentType: null,
  startDate: null,
  endDate: null,
  locationPlaceId: null,
  company: null
});

const newQualification = reactive({
  school: null,
  field: null,
  degreeLevel: null,
  startDate: null,
  endDate: null
});

const fetchSkills = async () => {
  const response = await api.get('/api/skill');
  skills.value = response.data;
};

const fetchRoles = async () => {
  const response = await api.get('/api/role');
  roles.value = response.data;
};

const fetchEmploymentTypes = async () => {
  const response = await api.get('/api/employment-type');
  employmentTypes.value = response.data;
};

const fetchFields = async () => {
  const response = await api.get('/api/field');
  fields.value = response.data;
};

const fetchDegreeLevels = async () => {
  const response = await api.get('/api/degree-level');
  degreeLevels.value = response.data;
};

const fetchEmployeeSkills = async () => {
  const response = await api.get('/api/employee/' + userId + '/skill');
  let skills = response.data;
  selectedSkills.value = skills.map(skill => skill.id);
};

const addExperience = () => {
  api.post(`/api/employee/${userId}/experience`, {
    roleId: newExperience.role,
    employmentTypeId: newExperience.employmentType,
    startDate: newExperience.startDate,
    endDate: newExperience.endDate,
    locationPlaceId: newExperience.locationPlaceId,
    company: newExperience.company,
    userId: userId
  });

  newExperience.role = null;
  newExperience.employmentType = null;
  newExperience.startDate = null;
  newExperience.endDate = null;
  newExperience.locationPlaceId = null;
  newExperience.company = null;
  addingExperience.value = false;
};

const addQualification = () => {
  api.post(`/api/employee/${userId}/qualification`, {
    school: newQualification.school,
    fieldId: newQualification.field,
    degreeLevelId: newQualification.degreeLevel,
    startDate: newQualification.startDate,
    endDate: newQualification.endDate,
    userId: userId
  });

  newQualification.school = null;
  newQualification.field = null;
  newQualification.degreeLevel = null;
  newQualification.startDate = null;
  newQualification.endDate = null;
  addingQualification.value = false;
};

const submitForm = async () => {
  console.log(selectedSkills.value);
  const data = {
    skillIds: selectedSkills.value,
  };
  await api.patch('/api/employee/' + userId, data);
  router.push('/employee/' + userId);
  
};

const setPlace = (place) => {
  let location = {
    placeId: place.place_id,
    name: place.name,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    country: place.address_components.find(component => component.types.includes('country')).long_name,
    countryCode: place.address_components.find(component => component.types.includes('country')).short_name,
  }

  //make sure the location exists or is created
  api.post('/api/location', location)
    .then(response => {
      newExperience.locationPlaceId = response.data.placeId;
      console.log(response.data)
    })
    .catch(error => {
      console.error(error)
    })
}

fetchSkills();
fetchRoles();
fetchEmploymentTypes();
fetchDegreeLevels();
fetchFields();
fetchEmployeeSkills();
</script>

<script>
export default {
  setup() {
    return {
      userId,
      employee,
      skills,
      roles,
      employmentTypes,
      selectedSkills,
      addingExperience,
      addingQualification,
      newExperience,
      newQualification,
      addExperience,
      addQualification,
      submitForm
    };
  }
};
</script>


<style scoped>
.create-job {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
}

.form-section h3 {
  margin-top: 0;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  flex-basis: calc(50% - 1rem);
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}


.form-group select,
.form-group input[type="date"],
.form-group input[type="text"],
.form-group input.pac-target-input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.form-group select[multiple] {
  height: 6rem;
}

.form-group button[type="submit"] {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.25rem;
}

.form-group button[type="submit"]:hover {
  background-color: #4caf50;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover {
  background-color: #2c5e2e;
}

button[type="submit"] {
  background-color: #4caf50;
}

.container {
  display: flex;
  flex-direction: row;
}

.experiences {
  flex: 1;
  margin-right: 20px;
}

.qualifications {
  flex: 1;
}

</style>