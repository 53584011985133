<template>
    <div class="employee-dashboard">
        <MenuComponent :userId="Number(userId)" />
        <EmployeeMainPage :userId="Number(userId)" />
    </div>
</template>

<script>
import EmployeeMainPage from '@/components/EmployeeMainPage.vue';
import MenuComponent from '@/components/MenuComponent.vue';

export default {
    name: 'EmployeeDashboard',
    components: {
        MenuComponent,
        EmployeeMainPage
    },
    props: ["userId"],
    mounted() {
        console.log("User id " + this.userId);
    }
}
</script>

<style>
.employee-dashboard {
  display: flex;
  flex-grow: 1;
}
</style>