<script setup>
import { reactive } from 'vue'
import { inject } from 'vue'

const api = inject('$api');
const signalR = inject('$signalR');

const qualifications = reactive([])

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

api.get('/api/employee/' + props.userId + '/qualification/')
    .then(response => {
      qualifications.push(...response.data);
    })
    .catch(error => {
        console.log(error);
    });

signalR.on('qualificationCreated', (qualification) => {
    console.log('Qualification created:', qualification)
    qualifications.push(qualification)
});


</script>

<template>
    <table>
          <thead>
            <tr>
              <th>School</th>
              <th>Degree Level</th>
              <th>Field of Study</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="qualification in qualifications">
              <td>{{ qualification.school }}</td>
              <td>{{ qualification.degreeLevel.name }}</td>
              <td>{{ qualification.field.name }}</td>
              <td>{{ qualification.startDate }}</td>
              <td>{{ qualification.endDate }}</td>
            </tr>
          </tbody>
        </table>
</template>


