<template>
  <div class="employer-dashboard">
    <MenuComponent :userId="Number(userId)" />
    <EmployerMainPage :userId="Number(userId)" />
  </div>
</template>
  
<script>
import MenuComponent from '@/components/MenuComponent.vue';
import EmployerMainPage from '@/components/EmployerMainPage.vue';
import { useUserStore } from '@/stores/user';
import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

export default {
  components: {
    MenuComponent,
    EmployerMainPage
  },
  props: ["userId"],
  mounted() {
    console.log("User id " + this.userId);

    const userStore = useUserStore()
  }
}
</script>
  
<style>
.employer-dashboard {
  display: flex;
  flex-grow: 1;
}
</style>
  