<template>
    <div class="create-org">
        <h2 v-if="userStore.employer && !userStore.employer.organizationId" class="title">Create Organization</h2>
        <h2 v-else class="title">Update Organization</h2>
        <div class="form-container">
            <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" v-model="organization.name" required>
            </div>
            <div class="form-group">
                <label for="industry">Industry:</label>
                <select id="industry" v-model="organization.industryId" @change="updateCategories" required>
                    <option value="">Select an industry</option>
                    <option v-for="industry in industries" :value="industry.id">{{ industry.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="category">Category:</label>
                <select id="category" v-model="organization.categoryId" required>
                    <option value="">Select a category</option>
                    <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="taxId">Tax ID:</label>
                <input type="text" id="taxId" v-model="organization.taxId" required>
            </div>
            <div class="form-group">
                <label for="address">Address:</label>
                <textarea id="address" v-model="organization.address" required></textarea>
            </div>
            <div class="form-group">
                <label for="website">Website:</label>
                <input type="text" id="website" v-model="organization.website" required>
            </div>
            <div class="form-group">
                <label for="contactNumber">Contact Number:</label>
                <input type="text" id="contactNumber" v-model="organization.contactNumber" required>
            </div>
            <div class="form-group">
                <label for="contactEmail">Contact Email:</label>
                <input type="email" id="contactEmail" v-model="organization.contactEmail" required>
            </div>
            <div class="form-group">
                <label for="contactPerson">Contact Person:</label>
                <input type="text" id="contactPersonName" v-model="organization.contactPersonName" required>
            </div>
        </div>
        <div class="form-container">
            <div class="form-group">
                <button v-if="userStore.employer && !userStore.employer.organizationId" type="submit"
                    @click="createOrganization">Create Organization</button>
                <button v-else type="submit" @click="createOrganization">Update Organization</button>
            </div>
        </div>
    </div>
</template>
  
<script>

import axios from 'axios';
import { useUserStore } from '@/stores/user';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL
});

export default {
    name: 'CreateOrg',
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    setup() {
        const userStore = useUserStore()

        return {
            userStore
        }
    },
    data() {
        return {
            organization: {
                employerId: this.userId,
                name: '',
                industryId: '',
                categoryId: '',
                taxId: '',
                address: '',
                website: '',
                contactNumber: '',
                contactEmail: '',
                contactPersonName: ''
            },
            industries: [], // replace with your list of industries
            categories: [] // will be updated based on the selected industry
        }
    },
    methods: {
        updateCategories() {
            const selectedIndustry = this.industries.find(industry => industry.id === this.organization.industryId);
            if (selectedIndustry) {
                this.categories = selectedIndustry.categories;
            } else {
                this.categories = [];
            }
            this.organization.categoryId = '';
        },
        createOrganization() {
            console.log('Creating organization:', this.organization);
            api.post('/api/organization', this.organization)
                .then(response => {
                    alert('Organization created successfully!');
                    this.userStore.employer.organizationId = response.data.id;
                    this.$router.push({ name: 'EmployerDashboard', params: { userId: this.userId } });
                })
                .catch((error) => {
                    console.log(error.response.data);
                    if (error.response.data.includes('already associated')) {
                        alert('You already have an organization!');
                    }

                });
        }
    },
    async mounted() {
        await api.get('/api/industry')
            .then(response => {
                this.industries = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        if (this.userStore.employer.organizationId) {
            api.get('/api/organization/' + this.userStore.employer.organizationId)
                .then(response => {
                    let organizationDto = response.data;
                    this.organization.address = organizationDto.address;
                    this.organization.contactEmail = organizationDto.contactEmail;
                    this.organization.contactNumber = organizationDto.contactNumber;
                    this.organization.contactPersonName = organizationDto.contactPersonName;
                    this.organization.employerId = organizationDto.employerId;
                    this.organization.industryId = organizationDto.industryId;
                    this.updateCategories();
                    this.organization.categoryId = organizationDto.categoryId;
                    this.organization.name = organizationDto.name;
                    this.organization.taxId = organizationDto.taxId;
                    this.organization.website = organizationDto.website;
                    this.organization.id = organizationDto.id;
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }
}
</script>
  
<style>
.create-org {
    display: block;
    width: 100%;
    flex-direction: column;
    margin: 1rem;
}

.title {
    display: block;
    text-align: center;
}

.form-container {
    display: block;
    width: 50%;
    margin: 1rem auto;
    text-align: left;
}

.form-group {
    display: block;
    size: 50%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.form-group label {
    margin-bottom: 0.1rem;
}

.form-group input,
.form-group select,
.form-group textarea {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (min-width: 768px) {
    .create-org {
        flex-direction: row;
        justify-content: center;
    }

    .form-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
</style>