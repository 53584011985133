<template>
    <div class="top-bar">
        <!-- Add your profile icon or any other content here -->
        <div v-if="userStore && userStore.profile && userStore.profile.photoUrl" @click="menuOpen = !menuOpen" class="user-profile">
            <img :src="userStore.profile.photoUrl" alt="User Profile Picture">
        </div>
        <div v-if="menuOpen" class="menu">
      <button @click="signOut">Sign Out</button>
    </div>
    </div>
    <div>
    
  </div>
</template>
  
<style>
.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: #ffffff;
    padding: 3px;
    z-index: 999;
}

.user-profile {
    position: fixed;
    top: 0;
    right: 0;
}

.user-profile img {
  padding: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 120px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.menu button {
  width: 100%;
}
</style>
  
<script setup>
import { ref } from 'vue';
import router from '@/router';
import { useUserStore } from '@/stores/user'

const count = ref(0)

const userStore = useUserStore();

const menuOpen = ref(false);

const signOut = () => {
  userStore.signOut();
  router.push('/');
  menuOpen.value = false;
}

</script>
  