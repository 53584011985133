<template>
  <div class="employer-main-page flex-grow">
      <div class="job-listings flex-grow">
        <JobListings :userId="userId"/>
        <JobApplications :userId="userId" />
      </div>
    </div>
</template>


<script>
import JobListings from '@/components/JobListings.vue';
import { useMainStore } from '@/stores/comms';
import { computed } from 'vue';
import JobApplications from './JobApplications.vue';

export default {
  components: {
    JobListings,
    JobApplications
  },
  props: ["userId"],
  setup() {
    const store = useMainStore();
    return {
    };
  },
};
</script>

<style scoped>

.employer-main-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

</style>
