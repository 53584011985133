import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    showCreateJobForm: false,
  }),
  actions: {
    toggleCreateJobForm() {
      this.showCreateJobForm = !this.showCreateJobForm;
    },
  },
});
