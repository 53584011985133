<template>
  <img alt="Vue logo" src="@/assets/logo.png">
  <div v-if="userStore.userProfile && userStore.userProfile.userType == 0">
    <h1>Welcome to the Job Board</h1>
    <p>Please sign in to continue</p>
    <UserTypeSelection />
  </div>

  <div v-else>
    <div id="g_id_onload" :data-client_id="googleClientId" data-context="signin" data-ux_mode="popup"
      data-callback="handleCredentialResponse" data-itp_support="true">
    </div>

    <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline" data-text="signin_with"
      data-size="small" data-logo_alignment="left">
    </div>
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.button {
  display: block;
  width: 200px;
  /* Set width of buttons */
  margin: 10px auto;
  /* Auto left and right margins center block elements */
  padding: 10px 20px;
  background-color: #4CAF50;
  /* Change as desired */
  color: white;
  /* Change as desired */
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.g_id_signin {
  display: block;
  margin: auto;
  width: 150px !important;
}
</style>

<script>
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import UserTypeSelection from '@/views/UserTypeSelection.vue';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

export default {
  components: {
    UserTypeSelection
  },
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    const userType = ref('employee');

    const place = ref(null);

    const handleCredentialResponse = async (response) => {
      const decoded = jwtDecode(response.credential);
      console.log('ID: ' + decoded.sub);
      console.log('Email: ' + decoded.email);
      console.log('Email verified: ' + decoded.email_verified);
      console.log('First Name: ' + decoded.given_name);
      console.log('Last Name: ' + decoded.family_name);
      console.log('Picture: ' + decoded.picture);
      console.log('Locale: ' + decoded.locale);
      console.log('Token: ' + response.credential);

      // Build the user object
      let user = {
        tokenId: response.credential,
        profile: decoded
      };

      // Send a POST request
      await verifyToken(user);
    };

    const verifyToken = async (user) => {
      const userStore = useUserStore();

      const response = await api.post('/api/google', JSON.stringify(user), {
        headers: {
          'content-type': 'application/json'
        }
      });

      // Wait for the response to complete
      const result = await response;

      // Check the response
      if (response.status != 200) {
        console.log(result);
        console.error('Token verification failed.');
        return;
      }

      if (result.data) {
        let user = result.data;

        console.log('Token verification succeeded.');
        /*
          Storing JWTs in local storage can expose to XSS attacks.
          As an alternative, you could consider using HttpOnly cookies to store the JWT
          And, always ensure your connections are secure (HTTPS) when dealing with authentication
        */
        console.log(user);
        localStorage.setItem('token', user.tokenId);
        userStore.setUserProfile(user);

        if (user.userType == 1) {
          console.log('Redirecting to employee page')
          router.replace('/employee/' + user.id);
        }
        else if (user.userType == 2) {
          await api.get('/api/employer/' + user.id)
            .then(response => {
              let employer = response.data
              userStore.setEmployer(employer)
            })
          console.log('Redirecting to employer page')
          router.replace('/employer/' + user.id);
        }
        else if (user.userType == 0) {
          console.log('Redirecting to user type selection')
          router.replace('/user-type');
        }


      } else {
        console.error('Token verification failed.');
      }
    };

    const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    console.log('Google Client ID: ' + googleClientId);

    return { userStore, userType, handleCredentialResponse, verifyToken, googleClientId };
  },
  mounted() {
    window.handleCredentialResponse = this.handleCredentialResponse;
  },
  beforeUnmount() {
    window.handleCredentialResponse = null;
  },
  methods: {
      setPlace(place) {
        console.log(place)
        console.log(place.name)
        console.log(place.place_id);
        console.log(place.geometry.location.lat())
        console.log(place.geometry.location.lng())
        console.log(place.address_components.find(comp => comp.types.includes('country')).long_name)
        console.log(place.address_components.find(comp => comp.types.includes('country')).short_name)
      }
  }
}
</script>


