<script setup>
import { reactive } from 'vue'
import { useUserStore } from '@/stores/user'
import { inject } from 'vue'
import { useRouter } from 'vue-router'


const api = inject('$api');
const router = useRouter();

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

const user = reactive({
    id: '',
    dateOfBirth: '',
    nationalities: [],
    genderId: '',
    address: '',
    taxId: '',
    phoneNumber: ''
});

const nationalities = reactive([]);
const genders = reactive([]);

api.get('/api/nationality')
    .then(response => {
        nationalities.push(...response.data);
    })
    .catch(error => {
        console.log(error);
    });

api.get('/api/gender')
    .then(response => {
        genders.push(...response.data);
    })
    .catch(error => {
        console.log(error);
    });


let userDto;
api.get('/api/user/' + props.userId)
    .then(response => {
        userDto = response.data;
        user.id = userDto.id;
        user.address = userDto.address;
        user.dateOfBirth = userDto.dateOfBirth;
        user.genderId = userDto.gender.id;
        user.nationalities = userDto.nationalities.map(nationality => nationality.id);
        user.phoneNumber = userDto.phoneNumber;
        user.taxId = userDto.taxId;
    })
    .catch(error => {
        console.log(error);
    });



const populateUser = () => {
    const userId = useUserStore().profile.id;
    user.id = userId;

    api.post('/api/user', user)
        .then(response => {
            router.push({ name: 'EmployeeDashboard', params: { userId: userId } });
            console.log(response.data)
        })
        .catch(error => {
            console.error(error)
        })
};

</script>


<template>
    <div>
        <h1>User Profile</h1>
        <form @submit.prevent="populateUser">
            <div class="form-container">
            <div class="form-group">
                <label for="dateOfBirth">Date of Birth:</label>
                <input type="date" id="dateOfBirth" v-model="user.dateOfBirth" required>
            </div>
            <div class="form-group">
                <label for="nationalities">Nationalities:</label>
                <select id="nationalities" v-model="user.nationalities" multiple>
                    <option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id">{{
                        nationality.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="gender">Gender:</label>
                <select id="gender" v-model="user.genderId" required>
                    <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{ gender.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="address">Address:</label>
                <textarea id="address" v-model="user.address" required></textarea>
            </div>
            <div class="form-group">
                <label for="taxId">Tax ID:</label>
                <input type="text" id="taxId" v-model="user.taxId" required>
            </div>
            <div class="form-group">
                <label for="phoneNumber">Phone Number:</label>
                <input type="tel" id="phoneNumber" v-model="user.phoneNumber" required>
            </div>
            <button type="submit">Save</button>
            </div>
        </form>
    </div>
</template>

<style scoped>
.create-job {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
}

.form-section h3 {
  margin-top: 0;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  flex-basis: calc(50% - 1rem);
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}


.form-group select,
.form-group input[type="date"],
.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.form-group select[multiple] {
  height: 6rem;
}

.form-group button[type="submit"] {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.25rem;
}

.form-group button[type="submit"]:hover {
  background-color: #4caf50;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover {
  background-color: #2c5e2e;
}

button[type="submit"] {
  background-color: #4caf50;
}
</style>
  