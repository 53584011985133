<template>
  <div class="create-job">
    <h2>Create Job Listing</h2>
    <div class="form-section">
      <h3>Offering</h3>
      <div class="form-row">
        <div class="form-group">
          <label for="position">Position:</label>
          <select id="position" v-model="job.positionId" required>
            <option value="">Select a position</option>
            <option v-for="position in positions" :key="position.id" :value="position.id">{{ position.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="location">Location:</label>
          <GMapAutocomplete placeholder="" @place_changed="setPlace">
          </GMapAutocomplete>
        </div>
        <div class="form-group">
          <label for="startDate">Start Date:</label>
          <input type="date" id="startDate" v-model="job.startDate" required>
        </div>
        <div class="form-group">
          <label for="endDate">End Date:</label>
          <input type="date" id="endDate" v-model="job.endDate" required>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="salary">Max Salary:</label>
          <input type="text" id="salary" v-model="job.maxSalary" required>
        </div>
        <div class="form-group">
          <label for="benefits">Benefits:</label>
          <select id="benefits" v-model="job.benefitIds" multiple required>
            <option v-for="benefit in benefits" :key="benefit.id" :value="benefit.id">{{ benefit.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="employmentType">Employment Type:</label>
          <select id="employmentType" v-model="job.employmentTypeId" required>
            <option value="">Select an employment type</option>
            <option v-for="employmentType in employmentTypes" :key="employmentType.id" :value="employmentType.id">{{
              employmentType.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="duties">Duties:</label>
          <textarea id="duties" v-model="job.duties" required></textarea>
        </div>
      </div>
    </div>
    <div class="form-section">
      <h3>Seeking</h3>
      <div class="form-row">
        <div class="form-group">
          <label for="degreeLevel">Degree Level:</label>
          <select id="degreeLevel" v-model="job.degreeLevelId">
            <option value="">Select a degree level</option>
            <option v-for="degreeLevel in degreeLevels" :key="degreeLevel.id" :value="degreeLevel.id">{{ degreeLevel.name
            }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="field">Field:</label>
          <select id="field" v-model="job.fieldId">
            <option value="">Select a field</option>
            <option v-for="field in fields" :key="field.id" :value="field.id">{{ field.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="skills">Skills:</label>
          <select id="skills" v-model="job.skillIds" multiple required>
            <option v-for="skill in skills" :key="skill.id" :value="skill.id">{{ skill.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="languages">Languages:</label>
          <select id="languages" v-model="job.languageIds" multiple>
            <option v-for="language in languages" :key="language.id" :value="language.id">{{ language.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" @click.prevent="createJobListing">Create Job Listing</button>
    </div>
  </div>
</template>
  
<script setup>
import { reactive } from 'vue'
import { useUserStore } from '@/stores/user'
import { inject } from 'vue'
import { useRouter } from 'vue-router'

const api = inject('$api');

const router = useRouter();

const job = reactive({
  title: '',
  positionId: '',
  fieldId: '',
  degreeLevelId: '',
  employmentTypeId: '',
  locationPlaceId: '',
  skillIds: [],
  languageIds: [],
  benefitIds: [],
  startDate: '',
  endDate: '',
  duties: '',
  maxSalary: '',
  userId: ''
})

const positions = reactive([])
const employmentTypes = reactive([])
const skills = reactive([])
const benefits = reactive([])
const fields = reactive([]);
const degreeLevels = reactive([]);
const languages = reactive([]);

api.get('/api/role')
  .then(response => {
    positions.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

api.get('/api/employment-type')
  .then(response => {
    employmentTypes.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

const locations = reactive([
  { id: 1, name: 'New York' },
  { id: 2, name: 'San Francisco' },
  { id: 3, name: 'Los Angeles' }
])

api.get('/api/skill')
  .then(response => {
    skills.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

api.get('/api/benefit')
  .then(response => {
    benefits.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

api.get('/api/field')
  .then(response => {
    fields.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

api.get('/api/degree-level')
  .then(response => {
    degreeLevels.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

api.get('/api/language')
  .then(response => {
    languages.push(...response.data);
  })
  .catch(error => {
    console.log(error);
  });

const userStore = useUserStore()

const createJobListing = () => {
  const userId = userStore.profile.id;
  job.userId = userId;

  api.post('/api/job-listing', job)
    .then(response => {
      alert('Job listing created successfully!');
      router.push({ name: 'EmployerDashboard', params: { userId: userId } });
      console.log(response.data)
    })
    .catch(error => {
      console.error(error)
    })
}

const setPlace = (place) => {
  let location = {
    placeId: place.place_id,
    name: place.name,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    country: place.address_components.find(component => component.types.includes('country')).long_name,
    countryCode: place.address_components.find(component => component.types.includes('country')).short_name,
  }

  //make sure the location exists or is created
  api.post('/api/location', location)
    .then(response => {
      job.locationPlaceId = response.data.placeId;
      console.log(response.data)
    })
    .catch(error => {
      console.error(error)
    })
}

</script>
  
<style>
.create-job {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
}

.form-section h3 {
  margin-top: 0;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  flex-basis: calc(50% - 1rem);
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}


.form-group select,
.form-group input[type="date"],
.form-group input[type="text"],
.form-group input.pac-target-input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.form-group select[multiple] {
  height: 6rem;
}

.form-group button[type="submit"] {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.25rem;
}

.form-group button[type="submit"]:hover {
  background-color: #4caf50;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover {
  background-color: #2c5e2e;
}

button[type="submit"] {
  background-color: #4caf50;
}
</style>
  