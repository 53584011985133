<script setup>
import { reactive } from 'vue'
import { inject } from 'vue'

const api = inject('$api');
const signalR = inject('$signalR');

const experiences = reactive([])

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

api.get('/api/employee/' + props.userId + '/experience/')
    .then(response => {
        experiences.push(...response.data);
    })
    .catch(error => {
        console.log(error);
    });

signalR.on('experienceCreated', (experience) => {
    console.log('Experience created:', experience)
    experiences.push(experience)
});


</script>

<template>
    <table>
          <thead>
            <tr>
              <th>Role</th>
              <th>Employment Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Location</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="experience in experiences">
              <td>{{ experience.role.name }}</td>
              <td>{{ experience.employmentType.name }}</td>
              <td>{{ experience.startDate }}</td>
              <td>{{ experience.endDate }}</td>
              <td>{{ experience.location.name }}</td>
              <td>{{ experience.company }}</td>
            </tr>
          </tbody>
        </table>
</template>


