<template>
    <div>
      <h2>Are you an employee or an employer?</h2>
      <div>
        <input type="radio" id="employee" name="userType" value="employee" v-model="userType" @change="logUserType">
        <label for="employee">Employee</label>
      </div>
      <div>
        <input type="radio" id="employer" name="userType" value="employer" v-model="userType" @change="logUserType">
        <label for="employer">Employer</label>
      </div>
      <button @click="createUserRecord">Continue</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  import { useUserStore } from '@/stores/user';
  import { ref } from 'vue';
  import { inject } from 'vue';

  export default {
    methods: {
      logUserType() {
        console.log(this.userType);
      }
    },
    setup() {
      const router = useRouter();
      const userStore = useUserStore();
      const api = inject('$api');
      const userType = ref('employee');

      const createUserRecord = async () => {
        // Get user from store
        const userStore = useUserStore();
        const user = userStore.profile;

        console.log(user.userType);
        console.log(userType.value);
        if (user.userType == 0) {
          if (userType.value === 'employee') {
            console.log('Create employee for user ' + user.id);
            let employeeCreateDto = {
              userId: user.id,
              phoneNumber: "string",
              skills: "string",
              experience: "string",
              desiredJobType: "string",
              isAvailable: true
            }

            await api.post('/api/employee', employeeCreateDto);
            user.userType = 1;

          } else if (userType.value === 'employer') {
            console.log('Create employer for user ' + user.id);
            let employerCreateDto = {
              userId: user.id,
              companyName: "string",
              location: "string",
              industry: "string",
              companySize: 0,
              description: "string"
            }
            await api.post('/api/employer', employerCreateDto);
            user.userType = 2;

            await api.get('/api/employer/' + user.id)
            .then(response => {
              let employer = response.data
              userStore.setEmployer(employer)
            })

          }
        }

        if (user.userType == 1) {
            console.log('Redirecting to employee page')
            router.replace('/employee/' + user.id + '/userProfile');
          }
          else if (user.userType == 2) {
            console.log('Redirecting to employer page')
            router.replace('/employer/' + user.id);
          }

        // set up request interceptor
        axios.interceptors.request.use(config => {
          config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
          return config;
        });
      }

      return {
        createUserRecord,
        userType,
        userStore,
        router
      }
    }
  }
  </script>

  <style scoped>
  label {
    display: inline-block;
    margin-left: 5px;
  }

  input[type=radio] {
    vertical-align: center;
    transform: scale(0.8);

  }

  button {
    margin-top: 10px;
  }

  </style>
  