import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { sessionStore } from '@/store'
import { HubConnectionBuilder } from '@microsoft/signalr'
import * as Sentry from '@sentry/vue';
import axios from 'axios'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL
})

const connection = new HubConnectionBuilder()
    .withUrl(api.defaults.baseURL + '/hubs/job-listing')
    .withAutomaticReconnect()
    .build();

connection.start()
    .then(() => {
        console.log('SignalR connection started successfully');
    })
    .catch((error) => {
        console.error('SignalR connection failed:', error);
    });

const app = createApp(App);
app.config.globalProperties.$signalR = connection;
app.config.globalProperties.$api = api;

app.provide('$api', api);
app.provide('$signalR', connection);

console.log("Running in " + import.meta.env.MODE);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["http://localhost:8080", /^https:\/\/arispetridis\.ml\/.*$/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDHrTZdA5AawuYwyHfp-T4MTY3TnHUq9vA',
        libraries: "places"
    },
}).use(sessionStore).use(router).mount('#app');


