<template>
    <div v-if="userStore.profile.userType==2" class="menu-component">
      <router-link :to="{ name: 'CreateJobListing', component: CreateJobListing, params: { userId: userId }, props: true } ">
        <button>Create Job</button>
      </router-link>
      <router-link v-if="userStore.employer && !userStore.employer.organizationId" :to="{ name: 'CreateOrg', component: CreateOrg, params: { userId: userId }, props: true } ">
        <button>Create Organization</button>
      </router-link>
      <router-link v-else :to="{ name: 'CreateOrg', component: CreateOrg, params: { userId: userId }, props: true } ">
        <button>Update Organization</button>
      </router-link>
    </div>
    <div v-else class="menu-component">
      <router-link :to="{ name: 'UserProfile', component: UserProfile, params: { userId: userId }, props: true } ">
        <button>User Profile</button>
      </router-link>
      <router-link :to="{ name: 'EmployeeProfile', component: EmployeeProfile, params: { userId: userId }, props: true } ">
        <button>Employee Profile</button>
      </router-link>
      <router-link :to="{ name: 'CreateJobSeeking', component: CreateJobSeeking, params: { userId: userId }, props: true } ">
        <button>Create Job Seeking</button>
      </router-link>
    </div>
  </template>
  
  <script>
  import { useUserStore } from '@/stores/user'
  import CreateOrg from '@/views/CreateOrg.vue'
  import CreateJobListing from '@/views/CreateJobListing.vue'
  import CreateJobSeeking from '@/views/CreateJobSeeking.vue'
  
  export default {
    setup() {
      const userStore = useUserStore()
      
      return {
        userStore,
      };
    },
    props: ["userId"]
  };
  </script>
  
  <style scoped>
  .menu-component {
    width: 200px;
    background-color: #ffffff;
    padding: 10px;
  }

  .menu-component button {
    width: 100%;
    margin-bottom: 10px;
  }
  </style>
  