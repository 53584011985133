<template>
  <div>
    <h1>Job Listings</h1>
    <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Position</th>
          <th>Location</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Salary</th>
          <th v-if="userStore.profile.userType == 1">Organization</th>
          <th v-if="userStore.profile.userType == 1">Apply</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="listing in listings" :key="listing.id">
          <td>{{ listing.title }}</td>
          <td>{{ listing.position.name }}</td>
          <td>{{ listing.location.name }}</td>
          <td>{{ listing.startDate }}</td>
          <td>{{ listing.endDate }}</td>
          <td>{{ listing.maxSalary }}</td>
          <td v-if="userStore.profile.userType == 1">{{ listing.organizationName }}</td>
          <td v-if="userStore.profile.userType == 1"><button @click="apply(listing)">Apply</button></td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useUserStore } from '@/stores/user'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

export default {
  name: 'JobListings',
  setup() {
    const userStore = useUserStore()

    return {
      userStore
    }
  },
  data() {
    return {
      listings: []
    }
  },
  created() {
    this.$signalR.on('jobListingCreated', (listing) => {
      console.log('Job listing created:', listing)
      this.listings.push(listing)
    });

  },
  mounted() {
    console.log('User id job listings:', this.userId);
    if (this.userStore.profile.userType == 2) {
      api.get('/api/job-listing/employer/' + this.userId)
        .then(response => {
          this.listings = response.data
        })
    }
    else if (this.userStore.profile.userType == 1) {
      api.get('/api/job-listing/employee/' + this.userId)
        .then(response => {
          this.listings = response.data
        })
    }
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  methods: {
    apply(listing) {
      const userId = this.userId;

      api.post('/api/job-application', {
        userId: userId,
        jobListingId: listing.id
      })
        .then(() => {
          console.log('Job application submitted successfully')
          this.listings = this.listings.filter(item => item.id !== listing.id)
          console.log('Job listing removed:', listing);
        })
        .catch(error => {
          console.log(error)
        })
    }
  },

}
</script>

<style scoped>

.table-container {
  display: flex;
  justify-content: center;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
}

th, td {
  padding: 3px;
  text-align: left;
  border: 1px solid #ddd;
}

</style>