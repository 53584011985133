<template>
  <div class="employee-main-page flex-grow">
    <div class="job-listings flex-grow">
      <JobListings :userId="userId" :key="index"/>
      <JobApplications :userId="userId" />
      <JobSeekings :userId="userId" />
    </div>
  </div>
</template>


<script>
import JobListings from '@/components/JobListings.vue';
import JobSeekings from '@/components/JobSeekings.vue';
import { useMainStore } from '@/stores/comms';

import JobApplications from './JobApplications.vue';
import { ref } from 'vue';

const index = ref(0);

export default {
  components: {
    JobListings,
    JobApplications,
    JobSeekings
  },
  props: ["userId"],
  setup() {
    const store = useMainStore();
    return {
    };
  },
  created() {

    console.log('index ', index.value);
    this.$signalR.on('jobApplicationDeleted', (listing) => {
      console.log('Job application deleted message in main page:', listing)
      index.value++;
      console.log('index ', index.value);

    })
  },
  data() {
        return {
            index
        }
    }
};
</script>

<style scoped>
.employee-main-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
</style>
