import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        profile: reactive({}),
        employer: reactive({})
    }),
    actions: {
        setUserProfile(profile) {
            console.log('setUserProfile', profile);
            this.profile = profile
        },
        signOut() {
            this.profile = {};
            localStorage.removeItem('user');
        },
        setEmployer(employer) {
            console.log('setEmployer', employer);
            this.employer = employer;
        }
    },
    persist: true
})
