<template>
  <div>
    <h1>Job Seekings</h1>
    <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Position</th>
          <th>Location</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Salary</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="seeking in seekings" :key="seeking.id">
          <td>{{ seeking.role.name }}</td>
          <td>{{ seeking.location.name }}</td>
          <td>{{ seeking.startDate }}</td>
          <td>{{ seeking.endDate }}</td>
          <td>{{ seeking.minSalary }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useUserStore } from '@/stores/user'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

export default {
  name: 'JobSeekings',
  setup() {
    const userStore = useUserStore()

    return {
      userStore
    }
  },
  data() {
    return {
      seekings: []
    }
  },
  created() {
    this.$signalR.on('jobSeekingCreated', (seeking) => {
      console.log('Job seeking created:', seeking)
      this.seekings.push(seeking)
    });

  },
  mounted() {
    console.log('User id job seekings:', this.userId);
    
      api.get('/api/job-seeking/employee/' + this.userId)
        .then(response => {
          this.seekings = response.data
        })
    
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  methods: {
    
  },

}
</script>

<style scoped>

.table-container {
  display: flex;
  justify-content: center;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
}

th, td {
  padding: 3px;
  text-align: left;
  border: 1px solid #ddd;
}

</style>